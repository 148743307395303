import { LocationsListType } from '@dist-property-frontend/types';
import { AreaTag } from '../../types';
import {
  ListSingleSelectFilterObject,
  DropDownRangeFilterObject,
  RangeQuery,
  FilterQuery,
} from '../Filters/FilterTypes';

type keyValueType = {
  [key: string]: string;
};

export const locationQueryFromAreaTags = (
  locationList: AreaTag[],
  storedShapeIds: string[],
) => {
  if (
    storedShapeIds?.length > 0 &&
    !storedShapeIds.every((item) => item === null || item === undefined)
  ) {
    const locations = storedShapeIds.map((storedShapeId: string) => {
      const locationId = storedShapeId.split('_');
      const location = locationList.find((element: LocationsListType) => {
        return element.id === locationId[0];
      });
      return location?.displayValue;
    });

    if (locations.length > 1) {
      return { location: locations };
    }

    if (locations.length === 1) {
      const splitId = storedShapeIds[0].split('_');
      return { locationPath: locations[0], radius: splitId[1] };
    }
  }
  return { locationPath: 'ireland' };
};

export const simpleFiltersToQueryParams = (arr: FilterQuery[]) =>
  arr.reduce(
    (accumulator: keyValueType, currentValue: ListSingleSelectFilterObject) => {
      const newValue = { [currentValue.name]: currentValue.values };
      return { ...accumulator, ...newValue };
    },
    {},
  );

export const rangeFiltersToQueryParams = (arr: RangeQuery[]) =>
  arr.reduce(
    (accumulator: keyValueType, currentValue: DropDownRangeFilterObject) => {
      const newValue = {
        [`${currentValue.name}_from`]: currentValue.from,
        [`${currentValue.name}_to`]: currentValue.to,
      };
      return { ...accumulator, ...newValue };
    },
    {},
  );
